* body,p{
    padding: 0;
    margin: 0;
    background-color:rgb(2, 2, 2);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.nifff-video-container{
    width: 100vw;
    height:auto;
    display:flex;
    justify-content:center;
    align-items:center;
}

.video-wrapper{
    position: relative;
    height: 100vh;

}

#video-nifff{
    width: auto;
    height: 100%;

}
#clicker{
    top: 0px;
    left: 0px;
    background: #0E0E0E;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 2;
    transition: 200ms;
}
#clicker img{
    width: 80%;
    height: auto;
    margin: 0 10% 0 10%;
    display: block;
    float: left;
    animation: imageAnim 0.1s  infinite;
}


@keyframes imageAnim {
    0% { opacity: 1; left: 0px; }
    30% { opacity: 0; left: 5px;}
    35%{ opacity: 1; left: 0px;}
    68%, 72% { opacity: 1; left: 2px;}
    100% { opacity: 1; }
  }

/* ---------------------- MOBILE CENTER ----------------------------------- */


@media only screen and (max-width: 600px) {
    #video-nifff {
      width: 100vw;
      height: 100vh;
    }
  }

 